
import * as storyblokRuntime$wOkoSsYr0n from '/opt/build/repo/node_modules/@nuxt/image/dist/runtime/providers/storyblok'
import * as netlifyRuntime$2xGLoGD2LP from '/opt/build/repo/node_modules/@nuxt/image/dist/runtime/providers/netlifyImageCdn'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536,
    "narrow": 1000,
    "1xl": 1300,
    "normal": 1400,
    "3xl": 1620,
    "wide": 1800
  },
  "presets": {},
  "provider": "netlify",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['storyblok']: { provider: storyblokRuntime$wOkoSsYr0n, defaults: {"baseURL":"https://a.storyblok.com"} },
  ['netlify']: { provider: netlifyRuntime$2xGLoGD2LP, defaults: {} }
}
        